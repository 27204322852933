import React, { Component } from 'react';
import Loading from './Loading';
import i18n from '../i18n';
import PreloadImage from 'react-preload-image';

const API = 'https://nicolashuart.com/admin/wp-json/wp/v2/';
const QUERY = 'video?_embed&filter[orderby]=menu_order&order=desc';

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: false, counter: 0 }
    }
    
    componentDidMount() {
        this.setState({ isLoading: true });
        fetch(API+QUERY)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    truncateContent(content, numWords) {
        return content.split(" ").splice(0,numWords).join(" ")+"...";
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
          return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        let mainUrl = "https://www.youtube.com/watch?v=";
        let posts = dataSet.map((post, index) => {
            function fPostHasImage(post, mode) {
                if (post.featured_media > 0){
                    let sourceUrl = post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url;
                    let imageAlt = post._embedded['wp:featuredmedia'][0].media_details.sizes.medium.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(post, "sourceUrl");
            let myId = post.acf.associated_album.ID;
            if (this.props.id == myId) {
                this.state.counter = this.state.counter + 1;
                return (
                    <div className="post" key={index}>
                        <div className="text">
                            <a className="title" href={mainUrl+post.acf.youtube_id} target="_blank">
                                <h1>{post.title.rendered}</h1>
                            </a>
                            <div className="excerpt" dangerouslySetInnerHTML={{__html: post.content.rendered }}></div>
                        </div>
                        <div className="image">
                            <div className="box" id="box1">
                                <a href={mainUrl+post.acf.youtube_id} target="_blank">
                                <div className="button"></div>
                                    <PreloadImage
                                        className="loading"
                                        src={imageUrl} 
                                        lazy
                                        duration="1000ms"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }
        });
        if (this.state.counter  > 0) {
            return (
                    <div className="relatedContent">
                        <div className="moreProjectsTitle">{i18n.t("related videos")}</div>
                            <div className={ this.props.display }>
                                { posts }
                        </div>
                    </div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }
}

export default Blog;