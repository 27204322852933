import React, { Component } from 'react';
import Loading from './Loading';
import PreloadImage from 'react-preload-image';
import i18n from '../i18n';
import btnSpotify from '../assets/btn-spotify.png';
import btnApple from '../assets/btn-apple.png';
import btnTidal from '../assets/btn-tidal.png';
import btnAmazon from '../assets/btn-amazon.png';
import btnYoutube from '../assets/btn-youtube.png';

const API = 'https://nicolashuart.com/admin/wp-json/wp/v2/';
const QUERY = 'banner?_embed&filter[orderby]=menu_order';

class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: false, currentObj: 0 }
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        let myQuery = QUERY;
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr")
            myQuery = "banner_fr?_embed&filter[orderby]=menu_order";
        fetch(API+myQuery)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
        //this.intervalId = setInterval(this.animateAlternateObject.bind(this), 8900);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        clearTimeout(this.timeoutId2);
    }

    animateAlternateObject() {
        let maxLimitObj = this.state.dataSet.length -1 ;
        
        if (this.state.currentObj === maxLimitObj) {
            this.timeoutId =  setTimeout(this.fadeOut(this.state.currentObj), 0);
            this.setState({ currentObj: 0});
            this.timeoutId =  setTimeout(this.fadeIn(this.state.currentObj), 0);
        }
        else if (this.state.currentObj === 0) {
            this.setState({ currentObj: this.state.currentObj + 1});
            this.timeoutId =  setTimeout(this.fadeOut(this.state.currentObj - 1), 0);
            this.timeoutId =  setTimeout(this.fadeIn(this.state.currentObj), 0);
        }
        else if (this.state.currentObj > 0) {
            this.setState({ currentObj: this.state.currentObj + 1});
            this.timeoutId =  setTimeout(this.fadeOut(this.state.currentObj - 1), 0);
            this.timeoutId =  setTimeout(this.fadeIn(this.state.currentObj), 0);
            
        }
       
        //console.log(this.state.currentObj);
    }

    selectObject(myObject) {
        let maxLimitObj = this.state.dataSet.length -1 ;
        clearInterval(this.intervalId);
        clearTimeout(this.timeoutId);
        this.setState({ currentObj: myObject});
        this.intervalId = setInterval(this.animateAlternateObject.bind(this), 8900);
        for (var i = 0; i <= maxLimitObj; i++) {
            this.fadeOut(i);
        }
        this.fadeIn(myObject);
    }

    fadeIn(myObject) {
        document.getElementById("obj"+myObject).classList.remove("myFadeOut");
        document.getElementById("obj"+myObject).classList.add("myFadeIn");
        document.getElementById("dot"+myObject).classList.add("active");
        //document.getElementById("quoteSign").classList.add("animate");
        //this.timeoutId2 = setTimeout(function(){ document.getElementById("quoteSign").classList.remove("animate");}, 6000);
    }

    fadeOut(myObject) {
        document.getElementById("obj"+myObject).classList.remove("myFadeIn");
        document.getElementById("obj"+myObject).classList.add("myFadeOut");
        document.getElementById("dot"+myObject).classList.remove("active");
    }

    render() {
            let link_spotify = "https://open.spotify.com/artist/5yS9PEanpPGoBRJ6PzHdH8?si=q9YRZ2cWT1OGs2vuf9PyAA";
            let link_apple = "https://music.apple.com/de/artist/nicolas-huart/1168399683?l=en";
            let link_tidal = "https://tidal.com/browse/artist/6248943";
            let link_amazon = "https://amazon.com/music/player/artists/B00PEVCULC/nicolas-huart?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_bzk7quXaz37XR041SY94OcFvF";
            let link_youtube = "https://youtube.com/nicolashuart";
            var imageUrl = "";
            let posts = this.state.dataSet.map((post, index) => {
                function fPostHasImage(post, mode) {
                    if (post.featured_media > 0)  {
                        let sourceUrl = post._embedded['wp:featuredmedia'][0].source_url;
                        if (mode === "sourceUrl")
                            return  sourceUrl;
                    }
                }
                imageUrl = fPostHasImage(post, "sourceUrl");
                let content = post.content.rendered;
                let lang = i18n.language.substr(0,2); 
            return (
                <div className="testimonial" id={"obj"+index} key={index}>
                    <div className="inLine">
                        <a href={post.acf.external_link} target="_blank"><div className="content" dangerouslySetInnerHTML={{__html: content}}></div></a>
                        <div className="byLine" dangerouslySetInnerHTML={{__html: post.title.rendered}}></div>
                        {/* <a href={post.acf.external_link} target="_blank">
                        <PreloadImage
                            className="loading"
                            src={imageUrl} 
                            lazy
                            duration="1000ms"
                        />
                        </a> */}
                    </div>
                    <div className="links">
                        <ul>
                            {link_spotify.length > 0 && <li><a href={'' + link_spotify} target="_blank"><img src={btnSpotify} /></a></li>}
                            {link_apple.length > 0 && <li><a href={'' + link_apple} target="_blank"><img src={btnApple} /></a></li>}
                            {link_tidal.length > 0 && <li><a href={'' + link_tidal} target="_blank"><img src={btnTidal} /></a></li>}
                            {link_youtube.length > 0 && <li><a href={'' + link_youtube} target="_blank"><img src={btnYoutube} /></a></li>}
                            {/* {link_amazon.length > 0 && <li><a href={'' + link_amazon} target="_blank"><img src={btnAmazon} /></a></li>} */}
                        </ul>    
                    </div>  
                </div>
            );
        });

        let navigation = this.state.dataSet.map((post, index) => {
            let addStyle;
            if (index === 0)
                addStyle = "active";
            return (
                <div onClick={() => this.selectObject(index)} className={"dot "+addStyle} id={"dot"+index}  key={index}>•</div>
            );
        });
        if (this.state.isLoading) {
            return <Loading />;
        }

        return (
            <div className="tint" id="myTint">
                        <PreloadImage
                            className="loading"
                            src={imageUrl}
                            duration="1000ms"
                        />
                <div className="testimonials" id="myTestimonials">
                    {posts}
                    <div className="navigation">{navigation}</div>
                </div>
            </div>
        );
    }
}

export default Testimonials;