import React, { Component } from 'react';
import ContactButton from './ContactButton';
import i18n from '../i18n';

const API = 'https://nicolashuart.com/admin/wp-json/wp/v2/';
const QUERY = 'pages/?slug=contact-banner';

class ContactBanner extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr") {
            addLang = "-fr";
        }fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        let content = this.state.dataSet.map((data) => { return ( data.content.rendered )});
         
        return (
            <div className="contactBanner">
                <div className="content">
                < ContactButton />
                    <div className="text">
                        <p dangerouslySetInnerHTML={{__html: content}}></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactBanner;