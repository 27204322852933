import React, { Component } from 'react';

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = { loc: this.props.loc }
    }

    render() {
        return (  
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        );
    }
}

export default Loading;