import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import About from '../components/About';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';
import ContactBanner from './../components/ContactBanner';

class AboutPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                 <Helmet>
                        <title>{i18n.t('meta site title')} / {i18n.t('about')}</title>
                </Helmet>
                <div className="wrapper">
                    <Navigation />
                    <Logo />
                </div>
                <div className="wrapperSqueeze onPage">
                    <About/>
                </div>
                <ContactBanner /> 
                <Footer />
            </div>
        );
    }
}

export default AboutPage;