import React, { Component } from 'react';
import Loading from './Loading';
import i18n from '../i18n';

const API = 'https://api.songkick.com/api/3.0/artists/700741/calendar.json?apikey=io09K9l3ebJxmxe2';

class Songkick extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        fetch(API)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        var events = this.state.dataSet.resultsPage.results.event;
        const listing = [];
        if (events != undefined) {
            events.forEach(function(item, index, array) {
                var id = events[index]["id"];
                var dateStart = events[index]["start"].date;
                var dateStartForm = new Date(dateStart);
                dateStartForm = dateStartForm.toDateString();

                var venue = events[index]["venue"]["displayName"];
                var location = events[index]["location"]["city"];
                listing.push(<li key={index}>{dateStartForm}<br />{venue}, {location}</li>)
            });
        }
        else {
            listing.push(<li>{i18n.t('no event announced')}</li>);
        }

        let posts = "";
            return (
                <div className="post">
                   <ul>
                     {listing}
                    </ul>
                </div>
            )
        return (
                <div className="songkick">
                    <div className="posts">
                        { posts }
                    </div>
                </div>
        );
    }
}

export default Songkick;