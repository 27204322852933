import React, { Component } from 'react';
import Loading from './Loading';
import Author from './Author';
import i18n from '../i18n';
import PreloadImage from 'react-preload-image';

const API = 'https://nicolashuart.com/admin/wp-json/wp/v2/';
const QUERY = 'pages/?_embed&slug=contact';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr") {
            addLang = "-fr";
        }fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        let page = this.state.dataSet.map((data, index) => {
            function fPostHasImage(data, mode) {
                if (data.featured_media > 0){
                    let sourceUrl = data._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;
                    let imageAlt = data._embedded['wp:featuredmedia'][0].media_details.sizes.large.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let title = data.title.rendered;
            let content = data.content.rendered;
            let imageUrl = fPostHasImage(data, "sourceUrl");
            return (
                <div className="contact" key={index}>
                    {/* <div className="anchor" id="contact"><u className="flash">{title}</u></div> */}
                    <div className="blockFull">
                        
                        <div className="blockLeft">
                            <p dangerouslySetInnerHTML={{__html: content}}></p>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div>
                { page }
            </div>
        );
    }
}

export default Contact;