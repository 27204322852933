import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import ContactBanner from '../components/ContactBanner';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

class ContactPage extends Component {
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{i18n.t('meta site title')} / {i18n.t('meta site title contact')}</title>
                </Helmet>
                <div className="wrapper">
                    <Navigation />
                    <Logo />
                </div>
                <div className="wrapperSqueeze" >
                    <Contact />
                </div>
                <ContactBanner />
                <Footer />
            </div>
        );
    }
}

export default ContactPage;