import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import ContactBanner from '../components/ContactBanner';
import Releases from '../components/Releases';
import Release from '../components/Release';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

class WorkPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let pathArray = window.location.pathname.split('/');
        let slug = pathArray[2];
        if (slug === undefined || slug === "") {
            return (
                <div>
                    <Helmet>
                        <title>{i18n.t('meta site title')} / {i18n.t('music')}</title>
                    </Helmet>
                    <div className="wrapper">
                        <Navigation />
                        <Logo />
                    </div>
                    <div className="wrapperSqueeze">
                        <div className="projectsPage">
                           <Releases />
                            <div className="spacer"></div>
                            <div className="spacerHalf"></div>
                        </div>
                    </div>
                    <div className="spacer"></div>
                    <ContactBanner />
                    < Footer />
                </div>
            );
        }
        else {
            return (
                <div>
                    <div className="wrapper">
                        <Navigation />
                        <Logo />
                    </div>
                    <div className="wrapperSqueeze">
                        <Release slug={slug} />
                    </div> 
                    <div className="spacer"></div>
                    <ContactBanner />
                    < Footer />
                </div>
            );
        }
    }
}

export default WorkPage;