import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import About from '../components/About';
import Releases from '../components/Releases';
import Videos from '../components/Videos';
import Logo from '../components/Logo';
import Banners from '../components/Banners';
import ContactBanner from '../components/ContactBanner';
import Footer from '../components/Footer';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

class HomePage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.fadeQuote);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.fadeQuote);
    }

    fadeQuote() {
        let increment = -0.8;
        let scrolled = window.scrollY;
    }
    
    render() {
        return (
            <div className="home">
                <Helmet> 
                    <title>{i18n.t('meta site title')}</title>
                </Helmet>
                
                <div className="wrapper" id="myBanner">

                    <Navigation mode="dark" />
                    <Logo loc="home" />
                    <Banners />
                </div>
                <div id="fullPage">
                    <div className="wrapperSqueeze">
                        <div className="projectsPage">
                            <div className="sectionTitle">
                                <div className="anchor" id="work"><a href="/videos"><u>{i18n.t('music')}</u></a></div>
                            </div>
                            <Releases />
                        </div>
                    </div>
                    
                    <div className="wrapperSqueeze">
                        <div className="blogPage">
                            <div className="sectionTitle">
                                <div className="anchor" id="work"><a href="/videos"><u>{i18n.t('videos')}</u></a></div>
                            </div>
                            <Videos display="grid" />
                        </div>
                    </div>
                    <About display="home" />
                    <ContactBanner />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default HomePage;