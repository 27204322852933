import React, { Component } from 'react';
import Loading from './Loading';
import i18n from '../i18n';
import Songkick from './Songkick';

const API = 'https://nicolashuart.com/admin/wp-json/wp/v2/';
const QUERY = 'pages/?_embed&slug=tour';

class Tour extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addLang = "";
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr") {
            addLang = "-fr";
        }fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }


    truncateContent(content, numWords) {
        let string = content.toString();
        return string.split(" ").splice(0,numWords).join(" ")+"...";
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        let page = this.state.dataSet.map((data, index) => {
            function fPostHasImage(data, mode) {
                if (data.featured_media > 0){
                    let sourceUrl = data._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;
                    let imageAlt = data._embedded['wp:featuredmedia'][0].media_details.sizes.large.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let title = data.title.rendered;
            let content = data.content.rendered;
            let imageUrl = fPostHasImage(data, "sourceUrl");
            return (
                <div className="tour" key={index}>
                    <br />
                    <Songkick /> 
                    <br /><br />
                    <h1>{i18n.t('past concerts')}</h1>
                    <div className="block">
                        <div className="block">
                            <p dangerouslySetInnerHTML={{__html: content}}></p>
                        </div>
                        {/* <div className="blockLeft">
                            <div className="image">
                                <div className="box">
                                    <PreloadImage
                                        className="loading"
                                        src={imageUrl} 
                                        lazy
                                        duration="1000ms"
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            );
        });
        return (
            <div className="wrapperSqueeze alternate">
                { page }
            </div>
        );
    }
}

export default Tour;