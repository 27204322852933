import React, { Component } from 'react';
import Author from './Author';
import iconInsta from './../assets/iconInsta.png';
import iconFacebook from './../assets/iconFacebook.png';

class ContactButton extends Component {

    render() {
         return (
            <div className="contactButton">
                {/* <a href="mailto:contact@nicolashuart.com" target="_blank" title="Email" rel="noopener noreferrer"><Author /></a> */}
                <a href="https://www.instagram.com/nicolashuart/" target="_blank" title="Instagram" rel="noopener noreferrer"><img src={iconInsta} alt="Instagram"/></a>
                <a href="https://www.facebook.com/nicolashuartmusic/" target="_blank" title="Facebook" rel="noopener noreferrer"><img src={iconFacebook} alt="Facebook"/></a>
            </div>
        );
    }
}

export default ContactButton;