import React, { Component } from 'react';

class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = { menuMobile: false, loc: this.props.loc }
    }

    render() {
        return (  
            <div id="logo"><a href='/'><b>NICOLAS<br /> HUART</b></a></div>
        );
    }
}

export default Logo;