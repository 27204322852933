import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Loading from '../components/Loading';
import Logo from '../components/Logo';
import ContactBanner from '../components/ContactBanner';
import Footer from '../components/Footer';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

const API = 'https://nicolashuart.com/admin/wp-json/wp/v2/';
const QUERY = 'pages/?slug=privacy';

class PrivacyPage extends Component {

    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        let addLang = "";
        let lang = i18n.language.substr(0,2); 
        if (lang === "fr") {
            addLang = "-fr";
        } fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        let title = this.state.dataSet.map((data) => { return ( data.title.rendered )});
        let content = this.state.dataSet.map((data) => { return ( data.content.rendered )});
        return (
            <div>
                <Helmet>
                        <title>{i18n.t('meta site title')} / {i18n.t('meta site title privacy')}</title>
                </Helmet>
                <div className="wrapper">
                    <Navigation />
                    <Logo />
                </div>
                <div className="wrapperSqueeze">
                    <div className="privacy">
                        <div className="sectionTitle">
                            <div className="anchor">{title}</div>
                        </div>
                        <div className="content" dangerouslySetInnerHTML={{__html: content}}></div>
                    </div>
                </div> 
                <ContactBanner />
                < Footer />
            </div>
        );
    }
}

export default PrivacyPage;