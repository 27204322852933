import React, { Component } from 'react';
import ReleasesMore from './ReleasesMore';
import Loading from './Loading';
import VideosRelated from './VideosRelated';
import PreloadImage from 'react-preload-image';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';
import btnSpotify from '../assets/btn-spotify.png';
import btnApple from '../assets/btn-apple.png';
import btnTidal from '../assets/btn-tidal.png';
import btnAmazon from '../assets/btn-amazon.png';
import btnYoutube from '../assets/btn-youtube.png';

const API = 'https://nicolashuart.com/admin/wp-json/wp/v2/';
const QUERY = 'projects?_embed&filter[orderby]=menu_order&order=asc';

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = { slug: this.props.slug, dataSet: [], isLoading: false}
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ isLoading: true });
        let addQuery = "";
        if (this.state.slug !== "")
            addQuery = "&slug="+this.state.slug;
        fetch(API+QUERY+addQuery)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        const { dataSet, isLoading, error } = this.state;
        if (error) {
            return <p>{error.message}</p>;
        }
        if (isLoading) {
            return <Loading />;
        }
        var exclude;
        let projects = dataSet.map((project, index) => {
            function fPostHasImage(project, mode) {
                if (project.featured_media > 0){
                    let sourceUrl = project._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url;
                    let imageAlt = project._embedded['wp:featuredmedia'][0].media_details.sizes.large.alt;
                    if (mode === "sourceUrl")
                        return  sourceUrl;
                    else if (mode === "alt")
                        return  imageAlt;  
                }
            }
            let imageUrl = fPostHasImage(project, "sourceUrl");
            let title = project.title.rendered;
            let content; let credits;
            let lang = i18n.language.substr(0,2); 
            if (lang === "en") {
                content = project.content.rendered;
                credits = project.acf.credits;
            }
            else {
                content = project.acf.content_fr;
                credits = project.acf.credits_fr;
            }
            let tracklist = project.acf.tracklist;
            let excerpt = project.excerpt.rendered;
            let link_spotify = project.acf.link_spotify;
            let link_apple = project.acf.link_apple_music;
            let link_tidal = project.acf.link_tidal;
            let link_amazon = project.acf.link_amazon;
            let link_youtube = project.acf.link_youtube;
            exclude = project.id;
            return (
                <div className="project flexMe" key={index} >
                    <Helmet>
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title music')} – {title}</title>
                    </Helmet>
                     <div className="banner">
                        <PreloadImage
                            className="loadingBanner"
                            src={imageUrl}
                            lazy
                            duration="1000ms"
                        />  
                        <span className="small" dangerouslySetInnerHTML={{__html: excerpt}}></span>
                        
                    </div>
                    <div>
                        <div className="anchor">{title}</div>
                        <div className="links">
                            <ul>
                                {link_spotify.length > 0 && <li><a href={'' + link_spotify} target="_blank"><img src={btnSpotify} /></a></li>}
                                {link_tidal.length > 0 && <li><a href={'' + link_tidal} target="_blank"><img src={btnTidal} /></a></li>}
                                {link_apple.length > 0 && <li><a href={'' + link_apple} target="_blank"><img src={btnApple} /></a></li>}
                                {link_youtube.length > 0 && <li><a href={'' + link_youtube} target="_blank"><img src={btnYoutube} /></a></li>}
                                {link_amazon.length > 0 && <li><a href={'' + link_amazon} target="_blank"><img src={btnAmazon} /></a></li>}
                            </ul>    
                        </div>  
                        <div className="content">
                            <div className="text">
                             <p dangerouslySetInnerHTML={{__html: content}}></p>
                             <div className="tracklist" dangerouslySetInnerHTML={{__html: tracklist}}></div>
                             <p className="credits" dangerouslySetInnerHTML={{__html: credits}}></p>
                            </div>
                        </div>
                       
                    </div>
                    <p>
                        <VideosRelated display="grid" id={project.id} />
                    </p>         
                </div>
            )
        });
        return (
            <div className="work">
                <div className="projects">
                    { projects }
                    <ReleasesMore excludeId={exclude} />
                </div>
            </div>
    );
    }
}

export default Project;