import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import VideoPage from '../pages/VideoPage';
import ReleasePage from '../pages/ReleasePage';
import ContactPage from '../pages/ContactPage';
import TourPage from '../pages/TourPage';
import PrivacyPage from '../pages/PrivacyPage';
import ImprintPage from '../pages/ImprintPage';
import i18n from '../i18n';
import { HelmetProvider } from 'react-helmet-async';

class Router extends Component {

    componentDidMount() {
        if(navigator.userAgent === 'ReactSnap') {
            // HIDE PRELOADED HTML TO REMOVE FLICKERING
            document.getElementById('App').style.setProperty("opacity", "0");
          }
    }

    render() {
        return (
            <BrowserRouter>
                <HelmetProvider>
                    <Switch>
                        <Route exact path='/' component={ HomePage }/>
                        <Route path='/about' component={ AboutPage }/>
                        <Route path='/videos' component={ VideoPage }/>
                        <Route path='/concerts' component={ TourPage }/>
                        <Route path='/contact' component={ ContactPage }/>
                        <Route path='/music' component={ ReleasePage }/>
                        <Route path='/privacy' component={ PrivacyPage }/>
                        <Route path='/imprint' component={ ImprintPage }/>
                    </Switch>
                </HelmetProvider>
            </BrowserRouter>
        );
    }
}

export default Router;